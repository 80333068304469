import OneImage from "../assets/image/1.jpg";
import TwoImage from "../assets/image/2.jpg";
import ThirdImage from "../assets/image/3.jpg";
// import FourthImage from "../assets/image/4.jpg";
import FifthImage from "../assets/image/5.jpg";
import SixthImage from "../assets/image/6.jpg";
import SeventhImage from "../assets/image/7.jpg";
import EighthImage from "../assets/image/8.jpg";
import NinthImage from "../assets/image/9.jpg";
import TenthImage from "../assets/image/10.jpg";
import EleventhImage from "../assets/image/11.jpg";
import TwelvethImage from "../assets/image/12.jpg";
import ThirtheenthImage from "../assets/image/13.jpg";
import FourteenthImage from "../assets/image/14.jpg";
import FiftheenthImage from "../assets/image/15.jpg";
import SixteenthImage from "../assets/image/16.jpg";
import SeventeenthImage from "../assets/image/17.jpg";
import EithteenthImage from "../assets/image/18.jpg";
import NinteenthImage from "../assets/image/19.jpg";
import TwentieenthImage from "../assets/image/20.jpg";
import TwentyoneImage from "../assets/image/21.jpg";
import TwentytwoImage from "../assets/image/22.jpg";
import TwentyThreeImage from "../assets/image/23.jpg";
import TwentyFourImage from "../assets/image/24.jpg";
import TwentyFiveImage from "../assets/image/25.jpg";
import TwentySixImage from "../assets/image/26.jpg";
import TwentySevenImage from "../assets/image/27.jpg";
import TwentyEightImage from "../assets/image/28.jpg";
import TwentyNineImage from "../assets/image/29.jpg";

function ImageCraousel() {
  return (
    <div>
      <div className="wrapper">
        <div className="photobanner">
          <img className="first" src={OneImage} alt="" />
          <img className="first" src={TwoImage} alt="" />
          <img className="first" src={ThirdImage} alt="" />
          <img className="first" src={FifthImage} alt="" />
          <img className="first" src={SixthImage} alt="" />
          <img className="first" src={SeventhImage} alt="" />
          <img className="first" src={EighthImage} alt="" />
          <img className="first" src={NinthImage} alt="" />
          <img className="first" src={TenthImage} alt="" />
          <img className="first" src={EleventhImage} alt="" />
          <img className="first" src={TwelvethImage} alt="" />
          <img className="first" src={ThirtheenthImage} alt="" />
          <img className="first" src={FourteenthImage} alt="" />
          <img className="first" src={FiftheenthImage} alt="" />
          <img className="first" src={SixteenthImage} alt="" />
          <img className="first" src={SeventeenthImage} alt="" />
          <img className="first" src={EithteenthImage} alt="" />
          <img className="first" src={NinteenthImage} alt="" />
          <img className="first" src={TwentieenthImage} alt="" />
          <img className="first" src={TwentyoneImage} alt="" />
          <img className="first" src={TwentytwoImage} alt="" />
          <img className="first" src={TwentyThreeImage} alt="" />
          <img className="first" src={TwentyFourImage} alt="" />
          <img className="first" src={TwentyFiveImage} alt="" />
          <img className="first" src={TwentySixImage} alt="" />
          <img className="first" src={TwentySevenImage} alt="" />
          <img className="first" src={TwentyEightImage} alt="" />
          <img className="first" src={TwentyNineImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ImageCraousel;
